import axios from "axios";
import firebaseConfig from "./firebaseConfig";
import firebase from "firebase/app";
import "firebase/auth";
import history from "./history";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

if (!firebase.apps.length) {
  // Configure Firebase.
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

// Set the AUTH token for any request
API.interceptors.request.use(async (config) => {
  let token = null;
  if (firebase.auth().currentUser) {
    token = await firebase.auth().currentUser.getIdToken();
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    delete config.headers.Authorization;
    // window.location.pathname = 'firebaseAuthLogin';
  }
  return config;
});
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      history.push("/login");
    } else {
      return Promise.reject(error);
    }
  }
);

export default API;
