import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  Box,
  MobileStepper,
  Button,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ShoppingCart,
  Close as CloseIcon,
} from "@mui/icons-material";
import Client from "shopify-buy";

const itemsPerPage = 3; // Number of variants to display per slide

const FILTERED_SKUS = [
  "BEECARE-001",
  "STARTER-KIT-002",
  "giftcard25",
  "beegnette50_sticker",
  "beehome_observer_patrizia_stalder",
  "seeds",
  "testproduct",
  "beehome_pro_suspension",
  "beesummer",
  "reed_small",
];

const ProductCarousel = ({ onAddToCart }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [variants, setVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const maxSteps = Math.ceil(variants.length / itemsPerPage);

  // Shopify Client
  const shopifyClient = Client.buildClient({
    domain: new URL(
      JSON.parse(process.env.REACT_APP_SHOPIFY_URL)[i18n.language]
    ).host,
    storefrontAccessToken: JSON.parse(
      process.env.REACT_APP_SHOPIFY_ACCESSTOKEN
    )[i18n.language],
    language: i18n.language.split("-")[1] ?? "DE",
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const fetchedProducts = await shopifyClient.product.fetchQuery({
          first: 150,
        });
        console.log("Fetched products:", fetchedProducts);
        const filteredVariants = fetchedProducts.flatMap((product) =>
          product.variants
            .filter((variant) => FILTERED_SKUS.includes(variant.sku))
            .map((variant) => ({
              ...variant,
              productTitle: product.title,
              description: product.descriptionHtml || product.description,
              image: product.images[0]?.src || "/images/placeholder.png",
            }))
        );

        setVariants(filteredVariants);
      } catch (error) {
        console.error("Error fetching Shopify products:", error);
      }
    };
    fetchProducts();
  }, []);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const getVisibleVariants = () => {
    return variants.slice(
      activeStep * itemsPerPage,
      (activeStep + 1) * itemsPerPage
    );
  };

  const handleCardClick = (variant) => {
    setSelectedVariant(variant);
  };

  const handleCloseModal = () => {
    setSelectedVariant(null);
  };

  return (
    <Box
      sx={{ width: "100%", flexGrow: 1, margin: "auto", textAlign: "center" }}
    >
      {variants.length > 0 ? (
        <>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={setActiveStep}
            enableMouseEvents
          >
            {Array.from({ length: maxSteps }).map((_, stepIndex) => (
              <div key={stepIndex} hidden={activeStep !== stepIndex}>
                <Grid container spacing={2} justifyContent="center">
                  {getVisibleVariants().map((variant) => (
                    <Grid item xs={4} key={variant.id}>
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => handleCardClick(variant)}
                      >
                        <CardMedia
                          component="img"
                          height="150"
                          image={variant.image}
                          alt={variant.productTitle}
                        />
                        <CardContent
                          sx={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              minHeight: "48px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              fontSize: "1rem",
                            }}
                          >
                            {variant.productTitle} - {variant.title}
                          </Typography>

                          <Typography
                            variant="body2"
                            sx={{ marginBottom: "8px" }}
                          >
                            {variant.price?.amount}{" "}
                            {variant.price?.currencyCode}
                          </Typography>

                          <Box sx={{ marginTop: "auto" }}>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<ShoppingCart />}
                              fullWidth
                              onClick={(e) => {
                                e.stopPropagation();
                                onAddToCart(variant);
                              }}
                            >
                              Add to Cart
                            </Button>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
          </SwipeableViews>

          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </>
      ) : (
        <Typography>Loading products...</Typography>
      )}

      {/* Modal Dialog */}
      <Dialog
        open={Boolean(selectedVariant)}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        {selectedVariant && (
          <>
            <DialogTitle>
              {selectedVariant.productTitle} - {selectedVariant.title}
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <img
                src={selectedVariant.image}
                alt={selectedVariant.productTitle}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  marginBottom: "16px",
                }}
              />
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html:
                    selectedVariant.description ||
                    "<p>No description available</p>",
                }}
              ></Typography>
              <Typography variant="h6" sx={{ marginTop: "12px" }}>
                {selectedVariant.price?.amount}{" "}
                {selectedVariant.price?.currencyCode}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onAddToCart(selectedVariant)}
                color="primary"
                variant="contained"
                startIcon={<ShoppingCart />}
              >
                Add to Cart
              </Button>
              <Button onClick={handleCloseModal} color="secondary">
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default ProductCarousel;
