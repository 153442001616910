import React, { useContext, useState } from "react";
import {
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Menu,
  MenuItem,
  Grid,
  Link,
  Hidden,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import API from "../../services/api";
import { useTranslation } from "react-i18next";
import BeehomeModal from "../modals/BeehomeModal";
import AlertDialog from "./AlertDialog";
import UIContext from "../../contexts/UIContext";
import { useFormatter } from "../../hooks/formatter.hooks";
import { AddAPhoto } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  homeImage: {
    maxWidth: 100,
    [theme.breakpoints.down("md")]: {
      maxWidth: 60,
    },
  },
  listitemAvatar: {
    marginLeft: "22px",
    marginRight: "22px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "9px",
      marginRight: "9px",
    },
  },
  listitemText: {
    marginLeft: "50px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "3px",
    },
  },
  listitemLink: {
    marginLeft: "3px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  listitemSecondaryAction: {
    right: "50px",
    [theme.breakpoints.down("md")]: {
      right: "23px",
    },
  },
}));

export default function BeeHomeList(props) {
  const rows = props.rows;
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [editHouse, setEditHouse] = useState({});
  const [modalOpenStatus, setModalOpenStatus] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [, , loader] = useContext(UIContext);
  const { getTypeImage, getTypeName } = useFormatter();

  const closeModal = () => {
    setModalOpenStatus(false);
    props.reloadData(false);
  };

  return (
    <div className={classes.root}>
      <List className={classes.root}>
        {rows &&
          rows.map((value) => {
            const labelId = `checkbox-list-label-${value.id}`;
            const shipping_label =
              value.inSeason &&
              value.inSeason.isSentIn &&
              value.inSeason.shipping_label
                ? value.inSeason.shipping_label
                : false;
            return (
              <ListItem
                key={value.id}
                role={undefined}
                alignItems="center"
                dense
              >
                <ListItemAvatar className={classes.listitemAvatar}>
                  <img
                    alt={value.type}
                    src={getTypeImage(value.type)}
                    className={classes.homeImage}
                  />
                </ListItemAvatar>
                <ListItemText
                  id={labelId}
                  primary={
                    <Typography variant="h5">
                      {value.name ? value.name : value.code}
                    </Typography>
                  }
                  secondary={value.name ? value.code : getTypeName(value.type)}
                  className={classes.listitemText}
                />
                {shipping_label && (
                  <ListItemText
                    id={labelId}
                    primary={
                      <Link
                        href={shipping_label}
                        target="_blank"
                        fontSize="medium"
                      >
                        {t("476")}
                      </Link>
                    }
                    className={classes.listitemLink}
                  />
                )}
                <ListItemSecondaryAction
                  className={classes.listitemSecondaryAction}
                >
                  <IconButton
                    id="button-beehomelist-moreverticon"
                    edge="end"
                    aria-label="delete"
                    onClick={(event) => {
                      setEditHouse(value);
                      setAnchorEl(event.currentTarget);
                    }}
                    color="primary"
                    size="large"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="menu-beehome"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={open}
                    onClose={() => {
                      setAnchorEl(null);
                    }}
                    elevation={1}
                  >
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setModalOpenStatus(true);
                      }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>{t("Edit")}</Grid>
                        <Grid item>
                          <IconButton
                            id="button-beehomelist-edit"
                            edge="end"
                            aria-label="edit"
                            color="primary"
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <MenuItem
                      component="a"
                      href={
                        "https://beta.beehome.net/observations/" + value.code
                      }
                      target="_blank" // Optional: Open in new tab
                      rel="noopener noreferrer"
                      onClick={() => setAnchorEl(null)}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>{t("BeeGuests")}</Grid>
                        <Grid item>
                          <IconButton
                            id="button-beehomelist-delete"
                            edge="end"
                            aria-label="delete"
                            color="primary"
                            size="large"
                          >
                            <AddAPhoto />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setOpenConfirmDialog(true);
                      }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>{t("Delete")}</Grid>
                        <Grid item>
                          <IconButton
                            id="button-beehomelist-delete"
                            edge="end"
                            aria-label="delete"
                            color="primary"
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </MenuItem>
                    {!(
                      editHouse &&
                      editHouse.inSeason &&
                      (editHouse.inSeason.isSentIn === false ||
                        (editHouse.inSeason.isSentIn &&
                          editHouse.inSeason.shipping_label === false))
                    ) && (
                      <Hidden smUp>
                        <MenuItem
                          onClick={() => {
                            setAnchorEl(null);
                            window.open(
                              editHouse.inSeason.shipping_label,
                              "_blank"
                            );
                          }}
                        >
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item>{t("476")}</Grid>
                            <Grid item>
                              <IconButton
                                id="button-beehomelist-shippinglabel"
                                edge="end"
                                aria-label="shippinglabel"
                                color="primary"
                                size="large"
                              >
                                <DownloadIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      </Hidden>
                    )}
                  </Menu>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
      </List>
      <BeehomeModal
        house={editHouse}
        open={modalOpenStatus}
        onClose={closeModal}
      />
      <AlertDialog
        type="confirm"
        title={t("Delete")}
        message={t("Are you sure you want to delete your BeeHome?")}
        open={openConfirmDialog}
        onTrueHandle={() => {
          loader.addTask("DELETE BEEHOME");
          setOpenConfirmDialog(false);
          API.delete(`me/houses/` + editHouse.id)
            .then(() => {
              loader.clearTask("DELETE BEEHOME");
              props.reloadData();
            })
            .catch((error) => {
              loader.clearTask("DELETE BEEHOME");
              console.error(error.response);
            });
        }}
        onFalseHandle={() => {
          setOpenConfirmDialog(false);
        }}
      />
    </div>
  );
}
